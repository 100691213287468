/* Loading 'Geist' & Inter Font-Family */
@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body {
  margin: 0;
  font-family: "Geist", serif;
  font-optical-sizing: auto;
  /* font-weight: 700;
  font-style: normal; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gfc-bg{
  background-color: #f9f9f9;
}

.circle {
  width: 100%;
  height: 150%;
  position: absolute;
  border-radius: 50%;
  top: 100%;
}

.main-header {
  color: red;
}

.link-style {
  position: relative;
  text-decoration: none;
  color: inherit; /* Maintain original text color */
}

.link-style::after {
  content: "";
  width: 0%;
  height: 1.5px;
  background-color: #00c2c6;
  display: block;
  margin-top: 2px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.2s linear;
}

.link-style:hover::after {
  width: 100%;
}

.panel { 
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: aliceblue;
}


.qxCTlb {

  /* min-height: 36px; */
  padding: 14px 20px;
  border-radius: 6px!important;
  font-weight: 900;
  text-transform: uppercase;
  color: white!important;
  letter-spacing: 0!important;
  font-family: unset!important;
  font-size: 14px!important;
  border-width: 0;
  cursor: pointer;
  word-break: break-word;
}